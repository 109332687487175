
.App{
  align-items: center;
  text-align: center;
}

body{
  background-color: #FFFFFF;
}


.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
