.codeSnipped
{
    font-style: italic;
    font-size: 90%;
}

.UIConsole
{   
    font-family: 'Courier New', Courier, monospace;
    width: 800px;
    height: 400px;
    background-color: #272727;
    color: #73BF94;
}