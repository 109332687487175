.sepSmall
{
  height: 10px;
}

.DropDown
{
  background-color: #FFFFFF;
  border-style:solid;

}

.TextInput
{
  background-color: #FFFFFF;
  width: 200px;
  border-style:solid;
}

.sep{
  width: 50%;
  margin-top: 20px;
}