body{
    align-items: center;
}

table
{
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
}

.LineInList
{
    padding: 5px;
}